<template>
  <div class="relative pb-2 mb-2 sm:pb-3 sm:mb-3 border-b border-gray-200 bg-white">
    <div
      class="absolute -inset-0.5 bg-white/50 backdrop-blur-[2px] z-10 transition-opacity duration-300"
      :class="(forceBlur || hasAny) ? 'opacity-1 pointer-events-auto': 'opacity-0 pointer-events-none'"
    />
    <slot />
  </div>
</template>

<script setup>
  const props = defineProps({
    forceBlur: Boolean
  });

  const {hasAny} = useSelection();
</script>
